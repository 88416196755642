
import { defineComponent, inject } from "vue";
import { ScrollSpy, Modal } from 'bootstrap';
import { useLazyQuery } from '@vue/apollo-composable';
import {
    GET_SITE_BY_ID,
    LIST_MARKETPLACE_CREDENTIALS_BY_SITE_ID,
    LIST_MARKETPLACE_PRODUCTS,
    LIST_MARKETPLACE_ORDER,
    LIST_MARKETPLACE_ORDER_ITEMS,
    LIST_STOCK_LOG_2,
    LIST_NOTIFICATIONS,
    LIST_PRODUCT_IMPORT_LOGS,
    LIST_SYSTEM_PRODUCTS,
} from "./SupportToolQueries";
import { ApolloError } from "@apollo/client/errors";
import { ApolloQueryResult } from "@apollo/client/core";
import SupportToolTable from "./SupportToolTable.vue";
import ResizeObserver from 'resize-observer-polyfill';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

interface ICustomError {
    name: string;
    message: string;
}

export default defineComponent({
    name: "SupportTool",
    components: { SupportToolTable, Datepicker },
    watch: {
        infos: {
            handler(newValue, oldValue) {
                const isAnyInfos = this.isAnyInfos();

                if (isAnyInfos) {
                    this.searchParams = this.infos;
                } else {
                    this.searchParams = undefined;
                }
            },
            deep: true
        },
        getters: {
            handler(newValue, oldValue) {
                this.handleRequiredInfos();
            },
            deep: true
        },
        checkers: {
            handler(newValue, oldValue) {
                this.handleRequiredInfos();
            },
            deep: true
        },
        loadingQueries: {
            handler(newValue, oldValue) {
                const isAnyQueryLoading = !Object.values(this.loadingQueries).every((v) => v === false);

                this.searchInProgress = isAnyQueryLoading;
            },
            deep: true
        },
        loadedData: {
            handler(newValue, oldValue) {
                const isAnyData = !Object.values(this.loadedData).every((v) => v === undefined);

                this.isAnyData = isAnyData;
            },
            deep: true
        },
        requiredInfos: {
            handler(newValue, oldValue) {
                const requiredInfosKeys = Object.keys(this.requiredInfos);
                for (const requiredInfosKeyIndex in requiredInfosKeys) {
                    const requiredInfoName = requiredInfosKeys[requiredInfosKeyIndex];
                    const requiredInfoValue = this.requiredInfos[requiredInfoName];

                    if (!requiredInfoValue) {
                        this.infos[requiredInfoName] = "";
                    }
                }
            },
            deep: true
        },
    },
    data() {
        let $mitt;

        //search
        const IDateRange: Array<string | null> = [null, null];
        const infos = {
            date_range: IDateRange,
            site_id: "",
            marketplace_id: "",
            system_product_id: "",
            marketplace_product_id: "",
            product_SKU: "",
            order_number: "",
            order_id: "",
            search_keyword: "",
        };
        const getters = {
            account_info: true,
            integrations_info: true,
            product_info: true,
            bundle_components: true,
            components_bundles: true,
            order_info: true,
            order_items: true,
            product_import_log: true,
            notifications: true,
            stock_log: true,
        };
        let requiredInfos = {
            date_range: true,
            site_id: true,
            marketplace_id: true,
            system_product_id: true,
            marketplace_product_id: true,
            product_SKU: true,
            order_number: true,
            order_id: true,
            search_keyword: true,
        };
        let queryErrors: ApolloError[] | ICustomError[] = [];
        let searchParams: {
            date_range: typeof IDateRange,
            site_id: string,
            marketplace_id: string,
            system_product_id: string,
            marketplace_product_id: string,
            product_SKU: string,
            order_number: string,
            order_id: string,
        } | undefined;
        const searchInProgress = false;
        const clearSearchInProgress = false;
        const clearResultsInProgress = false;

        //data
        const loadingQueries = {
            accountData: false,
            integrationsData: false,
            productData: false,
            orderData: false,
            orderItemsData: false,
            productImportLogs: false,
            notifications: false,
            stockLog: false,
            bundleComponents: false,
            componentsBundles: false,
        }
        const loadedData = {
            accountData: undefined as ApolloQueryResult<any> | undefined,
            integrationsData: undefined as ApolloQueryResult<any> | undefined,
            productData: undefined as ApolloQueryResult<any> | undefined,
            orderData: undefined as ApolloQueryResult<any> | undefined,
            orderItemsData: undefined as ApolloQueryResult<any> | undefined,
            productImportLogs: undefined as ApolloQueryResult<any> | undefined,
            notifications: undefined as ApolloQueryResult<any> | undefined,
            stockLog: undefined as ApolloQueryResult<any> | undefined,
            bundleComponents: undefined as ApolloQueryResult<any> | undefined,
            componentsBundles: undefined as ApolloQueryResult<any> | undefined,
        }
        const isAnyData = false;

        //products
        const productDataCursors: string[] = [];
        const productDataPage = 0;
        //product Import log
        const productImportLogsCursors: string[] = [];
        const productImportLogsPage = 0;

        //order info
        const orderDataCursors: string[] = [];
        const orderDataPage = 0;
        //order items
        const orderItemsDataCursors: string[] = [];
        const orderItemsDataPage = 0;

        //notifications // stock log
        const notificationsCursors: string[] = [];
        const notificationsPage = 0;
        const stockLogCursors: string[] = [];
        const stockLogPage = 0;

        //bundles
        const bundleComponentsCursors: string[] = [];
        const bundleComponentsPage = 0;
        const componentsBundlesCursors: string[] = [];
        const componentsBundlesPage = 0;

        //data modal
        let dataModal: Modal | undefined;
        //scrollSpy
        let scrollSpy: ScrollSpy | undefined;
        let scrollSpyEl: HTMLElement | null | undefined;

        //datepicker
        const datePickerFormatFrom = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `From: ${year}/${month}/${day}`;
        }
        const datePickerFormatTo = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `To: ${year}/${month}/${day}`;
        }

        return {
            $mitt,

            IDateRange,
            //search
            infos,
            getters,
            requiredInfos,
            queryErrors,
            searchParams,
            searchInProgress,
            clearSearchInProgress,
            clearResultsInProgress,

            //data
            loadingQueries,
            loadedData,
            isAnyData,

            //products
            productDataCursors,
            productDataPage,
            //product Import log
            productImportLogsCursors,
            productImportLogsPage,

            //orders info
            orderDataCursors,
            orderDataPage,
            //order items
            orderItemsDataCursors,
            orderItemsDataPage,

            //notifications // stock log
            notificationsCursors,
            notificationsPage,
            stockLogCursors,
            stockLogPage,

            //bundles
            bundleComponentsCursors,
            bundleComponentsPage,
            componentsBundlesCursors,
            componentsBundlesPage,

            //data modal
            dataModal,
            //ScrollSpy
            scrollSpy,
            scrollSpyEl,

            //datepicker
            datePickerFormatFrom,
            datePickerFormatTo,
        }
    },
    mounted() {
        this.$mitt = inject("$mitt");
        this.$mitt.on("open-data-modal", ({ header, body }) => { this.openDataModal({ header, body }) });

        this.handleRequiredInfos();

        this.dataModal = new Modal(document.getElementById('dataModal'));
        this.scrollSpyEl = document.getElementById("kt_content_container scrollspy-container");
        this.scrollSpy = new ScrollSpy(this.scrollSpyEl);

        this.handleRefreshScrollspy();
    },
    beforeUnmount() {
        this.$mitt.all.delete('open-data-modal', ({ header, body }) => { this.openDataModal({ header, body }) });
    },
    methods: {
        getNextPageCursor(pageIndex: number, cursorsArray: string[], dataPageHandler) {
            const cursorsAmount = cursorsArray.length;

            if (pageIndex + 1 <= cursorsAmount) {
                dataPageHandler(pageIndex + 1);
                return pageIndex;
            }

            return cursorsAmount - 1;
        },
        getPreviousPageCursor(pageIndex: number, dataPageHandler) {
            if (pageIndex > 0) {
                dataPageHandler(pageIndex - 1);
                return pageIndex - 1;
            }

            return 0;
        },
        setProductDataPage(page: number) {
            this.productDataPage = page;
        },
        setOrderDataPage(page: number) {
            this.orderDataPage = page;
        },
        setOrderItemsDataPage(page: number) {
            this.orderItemsDataPage = page;
        },
        setProductImportLogsPage(page: number) {
            this.productImportLogsPage = page;
        },
        setNotificationsPage(page: number) {
            this.notificationsPage = page;
        },
        setStockLogPage(page: number) {
            this.stockLogPage = page;
        },
        setBundleComponentsPage(page: number) {
            this.bundleComponentsPage = page;
        },
        setComponentsBundlesPage(page: number) {
            this.componentsBundlesPage = page;
        },
        openDataModal({ header, body }) {
            if (!this.dataModal || !this.isObjectOrArray(body)) { return };

            this.$mitt.emit("update-data-modal", { header, body })
            if (!this.dataModal._isShown) {
                this.dataModal.show();
            }
        },
        isObject(a) {
            return (!!a) && (a.constructor === Object);
        },
        isArray(a) {
            return (!!a) && (a.constructor === Array);
        },
        isObjectOrArray(x) {
            return this.isArray(x) || this.isObject(x);
        },
        handleClearResults() {
            if (this.searchInProgress || this.clearSearchInProgress || this.clearResultsInProgress) { return };


            this.clearResultsInProgress = true;

            this.queryErrors = [];
            this.loadedData = {
                accountData: undefined,
                integrationsData: undefined,
                productData: undefined,
                orderData: undefined,
                orderItemsData: undefined,
                productImportLogs: undefined,
                notifications: undefined,
                stockLog: undefined,
                bundleComponents: undefined,
                componentsBundles: undefined,
            };
            this.clearResultsInProgress = false;

            //products
            this.productDataCursors = [];
            this.productDataPage = 0;
            //orders
            this.orderDataCursors = [];
            this.orderDataPage = 0;
            //order items
            this.orderItemsDataCursors = [];
            this.orderItemsDataPage = 0;
            //notifications // stock log
            this.notificationsCursors = [];
            this.notificationsPage = 0;
            this.stockLogCursors = [];
            this.stockLogPage = 0;
        },
        handleClearSearch() {
            if (this.searchInProgress || this.clearSearchInProgress || this.clearResultsInProgress) { return };


            this.clearSearchInProgress = true;

            this.queryErrors = [];
            const emptyInfos = {
                date_range: [null, null],
                site_id: "",
                marketplace_id: "",
                system_product_id: "",
                marketplace_product_id: "",
                product_SKU: "",
                order_number: "",
                order_id: "",
                search_keyword: "",
            };
            this.infos = { ...emptyInfos };

            this.clearSearchInProgress = false;
        },
        handleSearch() {
            if (this.searchInProgress || this.clearSearchInProgress || this.clearResultsInProgress) { return };


            this.queryErrors = [];

            const gettersKeys = Object.keys(this.getters);
            for (const getterKeyIndex in gettersKeys) {
                const getterName = gettersKeys[getterKeyIndex];
                const getterValue = this.getters[getterName];

                switch (getterName) {
                    case 'account_info':
                        if (getterValue) { this.getSiteById() } else {
                            this.loadedData.accountData = undefined;
                        };
                        break;
                    case 'integrations_info':
                        if (getterValue) { this.getMarketplaceCredentialsBySiteId() } else {
                            this.loadedData.integrationsData = undefined;
                        };
                        break;
                    case 'product_info':
                        if (getterValue) {
                            this.productDataCursors = [];
                            this.productDataPage = 0;
                            this.getMarketplaceProducts();
                        } else {
                            this.loadedData.productData = undefined;
                            this.productDataCursors = [];
                            this.productDataPage = 0;
                        };
                        break;
                    case 'order_info':
                        if (getterValue) {
                            this.orderDataCursors = [];
                            this.orderDataPage = 0;
                            this.getMarketplaceOrder();
                        } else {
                            this.loadedData.orderData = undefined;
                            this.orderDataCursors = [];
                            this.orderDataPage = 0;
                        };
                        break;
                    case 'order_items':
                        if (getterValue) {
                            this.orderItemsDataCursors = [];
                            this.orderItemsDataPage = 0;
                            this.getMarketplaceOrderItems();
                        } else {
                            this.loadedData.orderItemsData = undefined;
                            this.orderItemsDataCursors = [];
                            this.orderItemsDataPage = 0;
                        };
                        break;
                    case 'product_import_log':
                        if (getterValue) {
                            this.productImportLogsCursors = [];
                            this.productImportLogsPage = 0;
                            this.getProductImportLogs();
                        } else {
                            this.loadedData.productImportLogs = undefined;
                            this.productImportLogsCursors = [];
                            this.productImportLogsPage = 0;
                        };
                        break;
                    case 'notifications':
                        if (getterValue) {
                            this.notificationsCursors = [];
                            this.notificationsPage = 0;
                            this.getNotifications();
                        } else {
                            this.loadedData.notifications = undefined;
                            this.notificationsCursors = [];
                            this.notificationsPage = 0;
                        };
                        break;
                    case 'stock_log':
                        if (getterValue) {
                            this.stockLogCursors = [];
                            this.stockLogPage = 0;
                            this.getStockLog();
                        } else {
                            this.loadedData.stockLog = undefined;
                            this.stockLogCursors = [];
                            this.stockLogPage = 0;
                        };
                        break;
                    case 'bundle_components':
                        if (getterValue) {
                            this.stockLogCursors = [];
                            this.stockLogPage = 0;
                            this.getBundleComponents();
                        } else {
                            this.loadedData.stockLog = undefined;
                            this.stockLogCursors = [];
                            this.stockLogPage = 0;
                        };
                        break;
                    case 'components_bundles':
                        if (getterValue) {
                            this.stockLogCursors = [];
                            this.stockLogPage = 0;
                            this.getComponentsBundles();
                        } else {
                            this.loadedData.stockLog = undefined;
                            this.stockLogCursors = [];
                            this.stockLogPage = 0;
                        };
                        break;
                }
            }

            return;
        },
        getComponentsBundles(cursor = "") {
            this.queryErrors = [];

            //build query options according to given info
            const filters = {
                systemProductId: this.infos.system_product_id !== "" ? this.infos.system_product_id : null,
                siteId: this.infos.site_id !== "" ? this.infos.site_id : null,
                sku: this.infos.product_SKU !== "" ? this.infos.product_SKU : null,
                cursor: (cursor !== "" ? cursor : null),
            };

            const componentsBundlesQuery = useLazyQuery(LIST_SYSTEM_PRODUCTS, { filters }, { fetchPolicy: 'network-only' });

            componentsBundlesQuery.onError((e) => {
                this.queryErrors.push(e);
                this.loadedData.componentsBundles = undefined;
                this.componentsBundlesCursors = [];
                this.componentsBundlesPage = 0;
                this.loadingQueries.componentsBundles = false;
                return;
            });

            componentsBundlesQuery.onResult((res) => {
                if (res.data.listSystemProducts.items.length === 0) {
                    this.queryErrors.push({
                        name: "Get Components Bundles", message: "not found",
                        graphQLErrors: [],
                        clientErrors: [],
                        networkError: null,
                        extraInfo: undefined
                    });
                    this.loadedData.componentsBundles = undefined;
                    this.componentsBundlesCursors = [];
                    this.componentsBundlesPage = 0;
                    this.loadingQueries.componentsBundles = false;
                    return;
                }

                let componentsBundles: any = [];
                for (const itemIndex in res.data.listSystemProducts.items) {
                    const item = res.data.listSystemProducts.items[itemIndex];
                    if (item.isBundleComponent) {
                        componentsBundles.push(item.marketplaceProducts);
                    } else {
                        componentsBundles.push({ data: null });
                    }
                }

                const componentsBundlesCursor = res.data.listSystemProducts.cursor;

                this.loadedData.componentsBundles = componentsBundles;
                if (this.componentsBundlesCursors.indexOf(componentsBundlesCursor) === -1) { this.componentsBundlesCursors.push(componentsBundlesCursor) };
                this.loadingQueries.componentsBundles = false;

                return;
            });

            this.loadingQueries.componentsBundles = true;
            componentsBundlesQuery.load();

            return;
        },
        getBundleComponents(cursor = "") {
            this.queryErrors = [];

            //build query options according to given info
            const filters = {
                systemProductId: this.infos.system_product_id !== "" ? this.infos.system_product_id : null,
                siteId: this.infos.site_id !== "" ? this.infos.site_id : null,
                sku: this.infos.product_SKU !== "" ? this.infos.product_SKU : null,
                cursor: (cursor !== "" ? cursor : null),
            };

            const bundleComponentsQuery = useLazyQuery(LIST_SYSTEM_PRODUCTS, { filters }, { fetchPolicy: 'network-only' });

            bundleComponentsQuery.onError((e) => {
                this.queryErrors.push(e);
                this.loadedData.bundleComponents = undefined;
                this.bundleComponentsCursors = [];
                this.bundleComponentsPage = 0;
                this.loadingQueries.bundleComponents = false;
                return;
            });

            bundleComponentsQuery.onResult((res) => {
                if (res.data.listSystemProducts.items.length === 0) {
                    this.queryErrors.push({
                        name: "Get Bundle Components", message: "not found",
                        graphQLErrors: [],
                        clientErrors: [],
                        networkError: null,
                        extraInfo: undefined
                    });
                    this.loadedData.bundleComponents = undefined;
                    this.bundleComponentsCursors = [];
                    this.bundleComponentsPage = 0;
                    this.loadingQueries.bundleComponents = false;
                    return;
                }

                let bundleComponents: any = [];
                for (const itemIndex in res.data.listSystemProducts.items) {
                    const item = res.data.listSystemProducts.items[itemIndex];
                    if (item.isBundleMain) {
                        bundleComponents.push(item.mainMarketplaceProduct);
                    } else {
                        bundleComponents.push({ data: null });
                    }
                }
                const bundleComponentsCursor = res.data.listSystemProducts.cursor;

                this.loadedData.bundleComponents = bundleComponents;
                if (this.bundleComponentsCursors.indexOf(bundleComponentsCursor) === -1) { this.bundleComponentsCursors.push(bundleComponentsCursor) };
                this.loadingQueries.bundleComponents = false;

                return;
            });

            this.loadingQueries.bundleComponents = true;
            bundleComponentsQuery.load();

            return;
        },
        getStockLog(cursor = "") {
            this.queryErrors = [];

            //build query options according to given info
            const filters = {
                systemProductId: this.infos.system_product_id !== "" ? this.infos.system_product_id : null,
                createdFrom: this.infos.date_range[0],
                createdTo: this.infos.date_range[1],
                cursor: (cursor !== "" ? cursor : null),
            };

            const stockLogQuery = useLazyQuery(LIST_STOCK_LOG_2, { filters }, { fetchPolicy: 'network-only' });

            stockLogQuery.onError((e) => {
                this.queryErrors.push(e);
                this.loadedData.stockLog = undefined;
                this.stockLogCursors = [];
                this.stockLogPage = 0;
                this.loadingQueries.stockLog = false;
                return;
            });

            stockLogQuery.onResult((res) => {
                if (res.data.listStockLog2.items.length === 0) {
                    this.queryErrors.push({
                        name: "Get Stock Log", message: "not found",
                        graphQLErrors: [],
                        clientErrors: [],
                        networkError: null,
                        extraInfo: undefined
                    });
                    this.loadedData.stockLog = undefined;
                    this.stockLogCursors = [];
                    this.stockLogPage = 0;
                    this.loadingQueries.stockLog = false;
                    return;
                }

                const stockLog = res.data.listStockLog2.items;
                const stockLogCursor = res.data.listStockLog2.cursor;

                this.loadedData.stockLog = stockLog;
                if (this.stockLogCursors.indexOf(stockLogCursor) === -1) { this.stockLogCursors.push(stockLogCursor) };
                this.loadingQueries.stockLog = false;

                return;
            });

            this.loadingQueries.stockLog = true;
            stockLogQuery.load();

            return;
        },
        getNotifications(cursor = "") {
            this.queryErrors = [];

            //build query options according to given info
            const filters = {
                marketplaceId: this.infos.marketplace_id !== "" ? this.infos.marketplace_id : null,
                searchKeyword: this.infos.search_keyword !== "" ? this.infos.search_keyword : null,
                cursor: (cursor !== "" ? cursor : null),
            };

            const notificationsQuery = useLazyQuery(LIST_NOTIFICATIONS, { filters }, { fetchPolicy: 'network-only' });

            notificationsQuery.onError((e) => {
                this.queryErrors.push(e);
                this.loadedData.notifications = undefined;
                this.notificationsCursors = [];
                this.notificationsPage = 0;
                this.loadingQueries.notifications = false;
                return;
            });

            notificationsQuery.onResult((res) => {
                if (res.data.listNotifications.items.length === 0) {
                    this.queryErrors.push({
                        name: "Get Notifications", message: "not found",
                        graphQLErrors: [],
                        clientErrors: [],
                        networkError: null,
                        extraInfo: undefined
                    });
                    this.loadedData.notifications = undefined;
                    this.notificationsCursors = [];
                    this.notificationsPage = 0;
                    this.loadingQueries.notifications = false;
                    return;
                }

                const notifications = res.data.listNotifications.items;
                const notificationsCursor = res.data.listNotifications.cursor;

                this.loadedData.notifications = notifications;
                if (this.notificationsCursors.indexOf(notificationsCursor) === -1) { this.notificationsCursors.push(notificationsCursor) };
                this.loadingQueries.notifications = false;

                return;
            });

            this.loadingQueries.notifications = true;
            notificationsQuery.load();

            return;
        },
        getProductImportLogs(cursor = "") {
            this.queryErrors = [];

            //build query options according to given info
            const filters = {
                internalId: (this.infos.order_id !== "" ? this.infos.order_id : null),
                storeOrderNo: (this.infos.order_number !== "" ? this.infos.order_number : null),
                siteId: (this.infos.site_id !== "" ? this.infos.site_id : null),
                createdFrom: this.infos.date_range[0],
                createdTo: this.infos.date_range[1],
                cursor: (cursor !== "" ? cursor : null),
            };

            const productImportLogsQuery = useLazyQuery(LIST_PRODUCT_IMPORT_LOGS, { filters }, { fetchPolicy: 'network-only' });

            productImportLogsQuery.onError((e) => {
                this.queryErrors.push(e);
                this.loadedData.productImportLogs = undefined;
                this.productImportLogsCursors = [];
                this.productImportLogsPage = 0;
                this.loadingQueries.productImportLogs = false;
                return;
            });

            productImportLogsQuery.onResult((res) => {
                if (res.data.listProductImportLogs.items.length === 0) {
                    this.queryErrors.push({
                        name: "Get Product Import Logs", message: "not found",
                        graphQLErrors: [],
                        clientErrors: [],
                        networkError: null,
                        extraInfo: undefined
                    });
                    this.loadedData.productImportLogs = undefined;
                    this.productImportLogsCursors = [];
                    this.productImportLogsPage = 0;
                    this.loadingQueries.productImportLogs = false;
                    return;
                }

                const productImportLogs = res.data.listProductImportLogs.items;
                const productImportLogsCursor = res.data.listProductImportLogs.cursor;

                this.loadedData.productImportLogs = productImportLogs;
                if (this.productImportLogsCursors.indexOf(productImportLogsCursor) === -1) { this.productImportLogsCursors.push(productImportLogsCursor) };
                this.loadingQueries.productImportLogs = false;

                return;
            });

            this.loadingQueries.productImportLogs = true;
            productImportLogsQuery.load();

            return;
        },
        getMarketplaceOrderItems(cursor = "") {
            this.queryErrors = [];

            //build query options according to given info
            const filters = {
                internalId: (this.infos.order_id !== "" ? this.infos.order_id : null),
                storeOrderNo: (this.infos.order_number !== "" ? this.infos.order_number : null),
                siteId: (this.infos.site_id !== "" ? this.infos.site_id : null),
                createdFrom: this.infos.date_range[0],
                createdTo: this.infos.date_range[1],
                cursor: (cursor !== "" ? cursor : null),
            };

            const orderItemsDataQuery = useLazyQuery(LIST_MARKETPLACE_ORDER_ITEMS, { filters }, { fetchPolicy: 'network-only' });

            orderItemsDataQuery.onError((e) => {
                this.queryErrors.push(e);
                this.loadedData.orderItemsData = undefined;
                this.orderItemsDataCursors = [];
                this.orderItemsDataPage = 0;
                this.loadingQueries.orderItemsData = false;
                return;
            });

            orderItemsDataQuery.onResult((res) => {
                if (res.data.listMarketplaceOrder.items.length === 0) {
                    this.queryErrors.push({
                        name: "Get Order Items", message: "not found",
                        graphQLErrors: [],
                        clientErrors: [],
                        networkError: null,
                        extraInfo: undefined
                    });
                    this.loadedData.orderItemsData = undefined;
                    this.orderItemsDataCursors = [];
                    this.orderItemsDataPage = 0;
                    this.loadingQueries.orderItemsData = false;
                    return;
                }

                let orderItemsData: any = [];
                for (const orderIndex in res.data.listMarketplaceOrder.items) {
                    const order = res.data.listMarketplaceOrder.items[orderIndex];
                    for (const orderItemIndex in order.marketplaceOrderItems) {
                        const orderItem = order.marketplaceOrderItems[orderItemIndex];
                        orderItemsData.push(orderItem);
                    }
                }
                if (orderItemsData.length === 0) { orderItemsData = undefined };
                const orderItemsDataCursor = res.data.listMarketplaceOrder.cursor;

                this.loadedData.orderItemsData = orderItemsData;
                if (this.orderItemsDataCursors.indexOf(orderItemsDataCursor) === -1) { this.orderItemsDataCursors.push(orderItemsDataCursor) };
                this.loadingQueries.orderItemsData = false;

                return;
            });

            this.loadingQueries.orderItemsData = true;
            orderItemsDataQuery.load();

            return;
        },
        getMarketplaceOrder(cursor = "") {
            this.queryErrors = [];

            //build query options according to given info
            const filters = {
                internalId: (this.infos.order_id !== "" ? this.infos.order_id : null),
                storeOrderNo: (this.infos.order_number !== "" ? this.infos.order_number : null),
                siteId: (this.infos.site_id !== "" ? this.infos.site_id : null),
                createdFrom: this.infos.date_range[0],
                createdTo: this.infos.date_range[1],
                cursor: (cursor !== "" ? cursor : null),
            };

            const orderDataQuery = useLazyQuery(LIST_MARKETPLACE_ORDER, { filters }, { fetchPolicy: 'network-only' });

            orderDataQuery.onError((e) => {
                this.queryErrors.push(e);
                this.loadedData.orderData = undefined;
                this.orderDataCursors = [];
                this.orderDataPage = 0;
                this.loadingQueries.orderData = false;
                return;
            });

            orderDataQuery.onResult((res) => {
                if (res.data.listMarketplaceOrder.items.length === 0) {
                    this.queryErrors.push({
                        name: "Get Order Info", message: "not found",
                        graphQLErrors: [],
                        clientErrors: [],
                        networkError: null,
                        extraInfo: undefined
                    });
                    this.loadedData.orderData = undefined;
                    this.orderDataCursors = [];
                    this.orderDataPage = 0;
                    this.loadingQueries.orderData = false;
                    return;
                }

                const orderData = res.data.listMarketplaceOrder.items;
                const orderDataCursor = res.data.listMarketplaceOrder.cursor;

                this.loadedData.orderData = orderData;
                if (this.orderDataCursors.indexOf(orderDataCursor) === -1) { this.orderDataCursors.push(orderDataCursor) };
                this.loadingQueries.orderData = false;

                return;
            });

            this.loadingQueries.orderData = true;
            orderDataQuery.load();

            return;
        },
        getMarketplaceProducts(cursor = "") {
            this.queryErrors = [];

            //build query options according to given info
            const queryOptions = {
                siteId: (this.infos.site_id !== "" ? this.infos.site_id : null),
                systemProductId: (this.infos.system_product_id !== "" ? this.infos.system_product_id : null),
                sku: (this.infos.product_SKU !== "" ? this.infos.product_SKU : null),
                cursor: (cursor !== "" ? cursor : null),
            };

            const productsDataQuery = useLazyQuery(LIST_MARKETPLACE_PRODUCTS, {
                siteId: queryOptions.siteId,
                systemProductId: queryOptions.systemProductId,
                sku: queryOptions.sku,
                cursor: queryOptions.cursor
            }, { fetchPolicy: 'network-only' });

            productsDataQuery.onError((e) => {
                this.queryErrors.push(e);
                this.loadedData.productData = undefined;
                this.productDataCursors = [];
                this.productDataPage = 0;
                this.loadingQueries.productData = false;
                return;
            });

            productsDataQuery.onResult((res) => {
                if (res.data.listMarketplaceProducts.items.length === 0) {
                    this.queryErrors.push({
                        name: "Get Product Info ERROR", message: "site_id, system_product_id, product_SKU or page not found",
                        graphQLErrors: [],
                        clientErrors: [],
                        networkError: null,
                        extraInfo: undefined
                    });
                    this.loadedData.productData = undefined;
                    this.productDataCursors = [];
                    this.productDataPage = 0;
                    this.loadingQueries.productData = false;
                    return;
                }

                const productData = res.data.listMarketplaceProducts.items;
                const productDataCursor = res.data.listMarketplaceProducts.cursor;

                this.loadedData.productData = productData;
                if (this.productDataCursors.indexOf(productDataCursor) === -1) { this.productDataCursors.push(productDataCursor) };
                this.loadingQueries.productData = false;
                return;
            });

            this.loadingQueries.productData = true;
            productsDataQuery.load();

            return;
        },
        getMarketplaceCredentialsBySiteId() {
            this.queryErrors = [];

            const integrationsDataQuery = useLazyQuery(LIST_MARKETPLACE_CREDENTIALS_BY_SITE_ID, { siteId: this.infos.site_id }, { fetchPolicy: 'network-only' });

            integrationsDataQuery.onError((e) => {
                this.queryErrors.push(e);
                this.loadedData.integrationsData = undefined;
                this.loadingQueries.integrationsData = false;
                return;
            });

            integrationsDataQuery.onResult((res) => {
                if (res.data.listMarketplaceCredentials.length === 0) {
                    this.queryErrors.push({
                        name: "Get Integrations Info ERROR", message: "site_id or integrations not found",
                        graphQLErrors: [],
                        clientErrors: [],
                        networkError: null,
                        extraInfo: undefined
                    });
                    this.loadedData.integrationsData = undefined;
                    this.loadingQueries.integrationsData = false;
                    return;
                }

                const integrationsData = res.data.listMarketplaceCredentials;

                this.loadedData.integrationsData = integrationsData;
                this.loadingQueries.integrationsData = false;
                return;
            });

            this.loadingQueries.integrationsData = true;
            integrationsDataQuery.load();

            return;
        },
        getSiteById() {
            this.queryErrors = [];

            const accountDataQuery = useLazyQuery(GET_SITE_BY_ID, { id: this.infos.site_id }, { fetchPolicy: 'network-only' });

            accountDataQuery.onError((e) => {
                this.queryErrors.push(e);
                this.loadedData.accountData = undefined;
                this.loadingQueries.accountData = false;
                return;
            });

            accountDataQuery.onResult((res) => {
                if (res.data.getSiteById === null) {
                    this.queryErrors.push({
                        name: "Get Account Info ERROR", message: "site_id not found",
                        graphQLErrors: [],
                        clientErrors: [],
                        networkError: null,
                        extraInfo: undefined
                    });
                    this.loadedData.accountData = undefined;
                    this.loadingQueries.accountData = false;
                    return;
                }

                const accountData = res.data.getSiteById;

                this.loadedData.accountData = accountData;
                this.loadingQueries.accountData = false;
                return;
            });

            this.loadingQueries.accountData = true;
            accountDataQuery.load();

            return;
        },
        handleRequiredInfos() {
            const requiredInfos = {
                date_range: this.getters.order_info || this.getters.order_items || this.getters.product_import_log || this.getters.stock_log,
                site_id: this.getters.account_info || this.getters.integrations_info || this.getters.product_info || this.getters.order_info || this.getters.order_items || this.getters.product_import_log || this.getters.bundle_components || this.getters.components_bundles,
                marketplace_id: this.getters.notifications,
                system_product_id: this.getters.product_info || this.getters.product_import_log || this.getters.stock_log || this.getters.bundle_components || this.getters.components_bundles,
                marketplace_product_id: this.getters.order_info || this.getters.order_items,
                product_SKU: this.getters.product_info || this.getters.bundle_components || this.getters.components_bundles,
                order_number: this.getters.order_info || this.getters.order_items,
                order_id: this.getters.order_info || this.getters.order_items,
                search_keyword: this.getters.notifications,
            };

            this.requiredInfos = requiredInfos;
            return;
        },
        isAnyRequiredInfos() {
            const isAnyRequiredInfos = !Object.values(this.requiredInfos).every((v) => v === false);
            return isAnyRequiredInfos;
        },
        isAnyInfos() {
            const isAnyInfos = !Object.values(this.infos).every((v) => v === "" || (v[0] === null && v[1] === null));
            return isAnyInfos;
        },
        handleRefreshScrollspy() {
            const test = new ResizeObserver((element) => {
                if (!this.scrollSpy) { return };
                this.scrollSpy.refresh();
            });

            if (this.scrollSpyEl === null || !this.scrollSpyEl) { return };

            test.observe(this.scrollSpyEl);
        }
    }
});
