import { gql } from "graphql-tag";

const GET_SITE_BY_ID = gql`
    query getSiteById($id: ID) {
        getSiteById(id: $id) {
            id
            subscription {
                id
                name
                description
                maxOrders
                pricePerMonth
                pricePerYear
                currency
                isDefault
                stripeMonthlyPlanId
                stripeYearlyPlanId
                v2Plans
                maxProducts
                royalMail
                stripeMonthlyPlanIdLt
                stripeYearlyPlanIdLt
                addOn
                provider
            }
            stripeCustomerId
            externalStripeSubscriptionId
            created
            limitReached
            ordersCount
            siteOrdersCount
            localOrdersCount
            customOrdersLimit
            customProductsLimit
            selfMergeLimit
            componentsPerBundleLimit
            componentToBundlesLimit
            multiWarehouseEnabled
            subBoughtDate
            subscribtionValidUntil
            nextSubscriptionDate
            royalMailSubscriptionId
            refUrl
            name
            website
            siteCountry
            whereSell
            whichCarriers
            describeUsage
            companySize
            industry
            jobRole
            featuresLooking
            planningProducts
            responsibilities
            useShopifyBilling
            phone
            tin
            planningMonthlyOrders
        }
    }
`;

const LIST_MARKETPLACE_CREDENTIALS_BY_SITE_ID = gql`
    query listMarketplaceCredentials($siteId: ID) {
        listMarketplaceCredentials(siteId: $siteId) {
            id
            marketplace {
                id
                name
            }
            created
            lastImport
            scheduledImport
            siteId
            status
            name
            importOrders
            importProducts
            inventoryImportSettings
            orderImportSettings
            extraInfo {
                activeProducts
                inactiveProducts
                soldOutProducts
                draftProducts
                expiredProducts
                autoRelist
                doNotSendEmailNotifications
                codeChallenge
                state
                codeVerifier
                access_token
                token_type
                expires_in
                refresh_token
                refresh_token_expires_in
                expires_in_date
                refresh_token_expires_in_date
                shop_id
                shop_name
                url
                currency_code
            }
        }
    }
`;

const LIST_MARKETPLACE_PRODUCTS = gql`
    query listMarketplaceProducts(
        $systemProductId: ID
        $siteId: ID
        $sku: String
        $cursor: String
    ) {
        listMarketplaceProducts(
            systemProductId: $systemProductId
            siteId: $siteId
            sku: $sku
            cursor: $cursor
        ) {
            items {
                id
                created
                updated
                siteId
                internalId
                title
                sku
                isMerged
                weight
                imagesRaw {
                    id
                    url
                }
                externalUrl
                isConfigurable
                isParent
                isFbaProduct
                price
                systemProductId
                systemProduct {
                    id
                    created
                    title
                    sku
                    price
                    minAvailable
                    maxAvailable
                    weight
                    imagesRaw
                    externalUrl
                }
            }
            cursor
        }
    }
`;

const LIST_MARKETPLACE_ORDER = gql`
    query listMarketplaceOrder(
        $internalId: ID
        $storeOrderNo: ID
        $siteId: ID
        $createdFrom: DateTime
        $createdTo: DateTime
        $cursor: String
    ) {
        listMarketplaceOrder(
            filters: {
                internalId: $internalId
                storeOrderNo: $storeOrderNo
                siteId: $siteId
                createdFrom: $createdFrom
                createdTo: $createdTo
                cursor: $cursor
            }
        ) {
            items {
                id
                internalId
                externalId
                created
                purchased
                updated
                shipByDate
                deliveryByDate
                siteId
                marketplaceCredentialId
                storeOrderNo
                siteOrderNumber
                status
                systemStatus
                paymentStatus
                price
                discount
                shippingPrice
                taxes
                priceCurrency
                weightG
                shippingService
                shippingCarrierUsed
                shipmentTrackingNumber
                fulfillmentChannel
                sellerFulfillmentOrderId
                isLocalShipped
                isMerged
                isImported
            }
            cursor
        }
    }
`;

const LIST_MARKETPLACE_ORDER_ITEMS = gql`
    query listMarketplaceOrder(
        $internalId: ID
        $storeOrderNo: ID
        $siteId: ID
        $createdFrom: DateTime
        $createdTo: DateTime
        $cursor: String
    ) {
        listMarketplaceOrder(
            filters: {
                internalId: $internalId
                storeOrderNo: $storeOrderNo
                siteId: $siteId
                createdFrom: $createdFrom
                createdTo: $createdTo
                cursor: $cursor
            }
        ) {
            items {
                marketplaceOrderItems {
                    created
                    siteId
                    systemProductId
                    marketplaceProductId
                    name
                    sku
                    quantity
                    price
                }
            }
            cursor
        }
    }
`;

const LIST_STOCK_LOG_2 = gql`
    query listStockLog2(
        $systemProductId: ID
        $createdFrom: DateTime
        $createdTo: DateTime
        $cursor: String
    ) {
        listStockLog2(
            filters: {
                systemProductId: $systemProductId
                createdFrom: $createdFrom
                createdTo: $createdTo
                cursor: $cursor
            }
        ) {
            items {
                id
                created
                systemProductId
                reasonId
                reasonText
                hold
                quantity
                systemProduct {
                    id
                    created
                    title
                    sku
                    price
                    minAvailable
                    maxAvailable
                    weight
                    imagesRaw
                    externalUrl
                    isBundleComponent
                    isBundleMain
                }
            }
            cursor
        }
    }
`;

const LIST_NOTIFICATIONS = gql`
    query listNotifications(
        $marketplaceCredentialId: ID
        $searchKeyword: String
        $cursor: String
    ) {
        listNotifications(
            filters: {
                marketplaceCredentialId: $marketplaceCredentialId
                searchKeyword: $searchKeyword
                cursor: $cursor
            }
        ) {
            items {
                id
                created
                title
                siteId
                marketplaceCredentialId
                externalUrl
                marketplaceCredentials {
                    id
                    marketplace {
                        id
                        name
                    }
                    created
                    lastImport
                    scheduledImport
                    siteId
                    status
                    name
                    importOrders
                    importProducts
                    inventoryImportSettings
                    orderImportSettings
                    extraInfo {
                        activeProducts
                        inactiveProducts
                        soldOutProducts
                        draftProducts
                        expiredProducts
                        autoRelist
                        doNotSendEmailNotifications
                        codeChallenge
                        state
                        codeVerifier
                        access_token
                        token_type
                        expires_in
                        refresh_token
                        refresh_token_expires_in
                        expires_in_date
                        refresh_token_expires_in_date
                        shop_id
                        shop_name
                        url
                        currency_code
                    }
                }
            }
            cursor
        }
    }
`;

const LIST_PRODUCT_IMPORT_LOGS = gql`
    query listProductImportLogs(
        $siteId: ID
        $productId: ID
        $createdFrom: DateTime
        $createdTo: DateTime
        $cursor: String
    ) {
        listProductImportLogs(
            filters: {
                siteId: $siteId
                productId: $productId
                createdFrom: $createdFrom
                createdTo: $createdTo
                cursor: $cursor
            }
        ) {
            items {
                id
                created
                siteId
                productId
                marketplaceId
                message
                marketplaceProduct {
                    id
                    created
                    updated
                    siteId
                    internalId
                    title
                    sku
                    isMerged
                    weight
                    imagesRaw {
                        id
                        url
                    }
                    externalUrl
                    isConfigurable
                    isParent
                    isFbaProduct
                    price
                    systemProductId
                    systemProduct {
                        id
                        created
                        title
                        sku
                        price
                        minAvailable
                        maxAvailable
                        weight
                        imagesRaw
                        externalUrl
                    }
                }
                marketplace {
                    id
                    name
                }
            }
            cursor
        }
    }
`;

const LIST_SYSTEM_PRODUCTS = gql`
    query listSystemProducts(
        $systemProductId: ID
        $siteId: ID
        $sku: String
        $cursor: String
    ) {
        listSystemProducts(
            systemProductId: $systemProductId
            siteId: $siteId
            sku: $sku
            cursor: $cursor
        ) {
            items {
                id
                created
                title
                sku
                price
                minAvailable
                maxAvailable
                weight
                imagesRaw
                externalUrl
                mainMarketplaceProduct {
                    id
                    created
                    updated
                    siteId
                    internalId
                    title
                    sku
                    isMerged
                    weight
                    imagesRaw {
                        id
                        url
                    }
                    externalUrl
                    isConfigurable
                    isParent
                    isFbaProduct
                    price
                    systemProductId
                }
                marketplaceProducts {
                    id
                    created
                    updated
                    siteId
                    internalId
                    title
                    sku
                    isMerged
                    weight
                    imagesRaw {
                        id
                        url
                    }
                    externalUrl
                    isConfigurable
                    isParent
                    isFbaProduct
                    price
                    systemProductId
                }
                isBundleComponent
                isBundleMain
            }
            cursor
        }
    }
`;

export {
    GET_SITE_BY_ID,
    LIST_MARKETPLACE_CREDENTIALS_BY_SITE_ID,
    LIST_MARKETPLACE_PRODUCTS,
    LIST_MARKETPLACE_ORDER,
    LIST_MARKETPLACE_ORDER_ITEMS,
    LIST_STOCK_LOG_2,
    LIST_NOTIFICATIONS,
    LIST_PRODUCT_IMPORT_LOGS,
    LIST_SYSTEM_PRODUCTS,
};
